/* Filters.css */
@media (min-width: 1025px) {
  .row-container {
    flex-direction: row;
  }

  .filters-search {
    display: flex;
    padding-top: 20px;
  }

  .search-button {
    font-size: calc(0.3em + 1vw);
    box-shadow: 1px 1px 2px;
    border: none;
    cursor: pointer;
  }

  .filters-search {
    display: none;
  }

  .search-button:hover {
    color: red;
    cursor: pointer;
  }
}

/* Hide desktop navigation on screens smaller than or equal to 768px */
@media (max-width: 1024px) {
  .row-container {
    flex-direction: column;
  }

  .search-button {
    font-size: calc(0.8em + 1.2vw);
    margin-top: 15px;
    margin-right: 5%;
    box-shadow: none;
    border: 1px solid red;
    cursor: pointer;
  }
}

.row-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.filters {
  align-items: center;
  position: flex;
  /* Position relative to the Header component */
  width: 75%;
  background-color: #1f2224;
  padding: 20px;
  border-radius: 5px;
  margin: auto;
  color: white;
  display: flex;
  /* Use flexbox to arrange children in a row */
  flex-direction: column;
  /* Align children horizontally */
  justify-content: space-around;
  /* Distribute space evenly around the containers */
}

.search-button {
  background-color: #1f2224;
  border-radius: 5px;
  font-family: 'Noto Serif JP';
  color: #c8000f;
  font-weight: 100;
  letter-spacing: 0.07em;
  align-self: center;
  transition: 0.1s;
}



.search-button:active {
  transform: translateY(2px);
  /* Move the button down by 2px when it's clicked */
  box-shadow: 1px 1px 1px;
  /* Reduce the shadow to give a "pushed down" effect */
}

.row-container-center {
  display: flex;
  flex-direction: row;
  /* Align children horizontally */
  justify-content: space-around;
  /* Distribute space evenly around the containers */
  align-items: center;
  width: 100%;
}


.mediums-container,
.characters-container,
.selected-container {
  display: flex;
  flex-direction: column;
  background-color: #2c2f33;
  /* Adjust the background color as needed */
  padding: 15px;
  width: auto;
  /* Adjust the width to fit content */
  border-radius: 5px;
  margin-bottom: 20px;
  /* Space between this container and the next content */
  padding-top: 0;
}

.mediums-title,
.characters-title,
.selected-title {
  margin-top: 0;
  font-family: 'Noto Serif JP';
  font-size: calc(0.9em + 1.8vw);
  color: #c8000f;
  font-weight: 100;
  letter-spacing: 0.07em;
  margin-bottom: 0;
  border-bottom: 1px solid white;
  /* Adjust the color as needed */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
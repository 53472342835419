@media (min-width: 1025px) {
    .context-close-icon:hover,
    .context-jump-button:hover {
        color: red;
        cursor: pointer;
    }

    .image-and-title {
        height: 90%;
    }

    .image-navigation-icon-container:hover:not([disabled]) {
        opacity: 0.8;
        cursor: pointer;
    }

    
}

@media (max-width: 1025px) {
    .image-and-title {
        height: auto;
    }

}

@media (max-width: 768px) {
    .text-and-title {
        width: 85%
    }

    .notes-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .scrollable-container {
        max-height: 275px;
    }

    .image-overlay-title {
        flex-direction: column;
    }

    .image-navigation-icon-container {
        padding: 5px;
        width: 50px;
    }
    
    .image-navigation-container {
        height: 70%;
        width: 100%;
    }
    


}

@media (min-width: 769px) {
    .text-and-title {
        width: 62%
    }

    .notes-container {
        grid-template-columns: repeat(5, 1fr);
    }

    .scrollable-container {
        max-height: 500px;
    }

    .image-overlay-title {
        flex-direction: row;
    }

    .image-navigation-icon-container {
        width: 90px;
    }

    .image-navigation-container {
        height: 100%;
        width: 90%;
    }
}

.scrollable-container {
    overflow-y: auto;
    background-color: #282a2b;
    padding: 10px;
    padding-top: 15px;
    border-radius: 5px;
}

.note-group-title {
    color: #c8000f;
    user-select: none;
    cursor: pointer;
}
.notes-container {
    gap: 5%;
    display: grid;
    padding-right: 10px;
}

.image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.image-navigation-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image-navigation-icon-container {
    height: 100%;
    opacity: 0.2;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}


.image-navigation-icon {
    height: 5vw;
    fill: #c8000f;
    color: #c8000f;
    margin-bottom: 100px;
}


.image-content {
    position: relative;
    width: 90%;
    height: 90%;
    padding: 20px 20px 5px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-and-title {
    display: flex;
    width: fit-content;
    justify-content: center;
    flex-direction: column;
}

.image-title-container {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.text-and-title {
    display: flex;
    height: 95%;
    justify-content: center;
    flex-direction: column;
    padding-top: 3%;
    padding-left: 3%;
    padding-right: 3%;
}

.image-overlay-sentences {
    color: white;
    height: 100%;
    overflow: auto;
    padding: 10px;
    padding-top: 5px;
    background-color: #2c3033;
    border-radius: 5px;
    user-select: text;
}

.image-overlay-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -10px;
    user-select: none;
}

.overlay-image {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    cursor: pointer;
    user-select: none;
}

.image-title {
    color: white;
    margin: 0;
}

.image-overlay-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
}
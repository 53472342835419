/* Results.css */
@media (min-width: 769px) {
  .dropdown-menu {
    min-width: 400px;
  }

  .dropdown-icon-main:hover, .dropdown-icon:hover {
    color: red;
    cursor: pointer;
  }
}

/* Hide desktop navigation on screens smaller than or equal to 768px */
@media (max-width: 768px) {
  .dropdown-menu {
    min-width: none;
    width: 90%;
  }

  .dropdown-icon-main:hover, .dropdown-icon:hover {
    color: red;
    cursor: pointer;
  }
}




.dropdown {
  position: relative;
  display: inline-block;
  font-family: 'Noto Serif JP';
  letter-spacing: 0.07em;
  width: 100%;
}



.checkbox-container {
  display: flex;
  align-items: center;
}


.dimmed {
  opacity: 0.5;
}

.es-list {
  max-height: 400px; /* Adjust this value as needed */
  overflow-y: auto; /* This will add a vertical scrollbar */
  padding-right: 2%;
}

.episode-list, .volume-list, .attribute-list, .section-list, .chapter-list {
  max-height: 200px; /* Adjust this value as needed */
  overflow-y: auto; /* This will add a vertical scrollbar */
  overflow-x: hidden;
}

.season-title, .volume-title, .character-title, .part-title {
  color: white;
  text-overflow: ellipsis;
  font-family: 'Noto Serif JP';
  letter-spacing: 0.07em;
}

.dropdown-icon {
  color: #c8000f;
  height: 15px;
  margin-left: 5px;
}

.dropdown-icon-main {
  color: #c8000f;
  height: 20px;
  margin-left: 5px;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: black;
  padding: 12px 16px;
  z-index: 1;
  color: white;
  border: 1px solid #c8000f;
  max-height: 400px;
  overflow-y: auto;
}

.episode-header, .volume-header, .attribute-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

}

.episode-list, .volume-list, .attribute-list, .chapter-list, .section-list  {
  padding-left: 2px;
  padding-right: 4%;
  padding-top: 2%;
  padding-bottom: 3%;
}

.episode-item, .chapter-item, .attribute-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  width: 100%;
  padding-bottom: 5px;
}

.episode-checked, .chapter-checked {
  color: white;
}



.episode-name, .chapter-name, .part-title, .section-title {
  display: flex;
  width: auto;
  align-items: center;
}



.episode-unchecked, .volume-unchecked, .attribute-checked, .chapter-unchecked {
  opacity: 0.5;
}

input[type="checkbox"] {
  background-color: #000000;
  accent-color: #c8000f;
}

input[type="text"] {
  border: none;
  border-bottom: 1px solid white; /* Adjust color and thickness as needed */
  background-color: transparent;
  outline: none;
  box-shadow: none;
  padding: 1%;
  color: white;
  width: 80%;
  margin-bottom: 1%;
}


::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.episode-list::-webkit-scrollbar-track {
  background: transparent;
}

input:disabled {
  opacity: 0.3;
}

/* InfoPage.css */
@media (min-width: 769px) {
  .info-content {
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 4%;
    width: 75%;
  }

  .info-main {
    padding-bottom: 5%;
    padding: 1%;
    padding-top: 0%;
  }
}

/* Hide desktop navigation on screens smaller than or equal to 768px */
@media (max-width: 768px) {
  .info-content {
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 8%;
    width: 80%;
  }

  .info-main {
    padding-top: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }
}


.info-page {
  display: flexbox;
  flex-direction: column;
  height: 100%;
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}

.info-header {
  font-family: 'Noto Serif JP';
  font-size: calc(1em + 1.4vw);
  color: #c8000f;
  font-weight: 100;
  letter-spacing: 0.07em;
  align-items: center;
  position: flex;
  border-radius: 5px;
  display: flex;
  position: relative;
  margin-top: 1%;
  margin-bottom: 1%;
}

.info-character-box {
  max-height: 400px;
  overflow-y: auto;
  background-color: #17181a;
  padding-right: 2%;
  border-radius: 5px;
}

.info-info {
  padding-left: 0.5%;
}

.info-collapse {
  max-height: 600px;
  overflow-y: auto;
  background-color: #17181a;
  padding: 1%;
  padding-right: 2%;
  border-radius: 5px;
}

.info-main {
  background-color: #17181a;
  margin-top: 2%;
  border-radius: 5px;
}

.info-character-box-small {
  max-height: 200px;
  overflow-y: auto;
  padding: 0.5%;
  padding-right: 2%;
  background-color: #17181a;
  border-radius: 5px;
}

.info-content {
  position: relative;
  background-color: #1f2224;
  border-radius: 5px;
  margin: auto;
  color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}
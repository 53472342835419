/* Results.css */
@media (min-width: 769px) {
  .scroll-top-button {
    font-size: calc(0.2em + 0.6vw);
  }

  .settings-spp {
    background: none;
    border: none;
    border-bottom: 1px solid #c8000f;
    width: fit-content;
    max-width: 3%;
    text-align: center;
    color: white;
    outline: none;
    display: flex;
  }

  .page-settings {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content-wrapper {
    width: 95%;
  }

  .sentence-box {
    width: 98%;
  }

  .results-header {
    margin: auto;
    margin-top: 3%;
  }

  .sentence-box-image {
    flex-direction: row;
    margin-bottom: none;
    padding: 0px 0px 0px 10px;
    margin-right: 0px;
    flex: 1;
  }

  .character-box {
    padding-left: 5px;
    padding-right: 8px;
    margin: 10px 0 10px 10px;
    /* top right bottom left */
    flex-direction: row;
    justify-content: left;
    min-width: 9%;
    white-space: nowrap;
  }

  .character-box img {
    display: flex;
    width: 42px;
    height: 42px;
    padding: 2px 2px 2px 2px;
    margin-right: 2%;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .character-box-text {
    display: flex;
  }

  .results-container {
    padding: 20px;
  }

}

/* Hide desktop navigation on screens smaller than or equal to 768px */
@media (max-width: 768px) {
  .scroll-top-button {
    font-size: calc(0.6em + 0.6vw);
  }

  .settings-spp {
    background: none;
    border: none;
    border-bottom: 1px solid #c8000f;
    width: fit-content;
    max-width: 8%;
    color: white;
    text-align: center;
    outline: none;
    align-items: center;
    display: flex;
    float: right;
  }

  .page-settings {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-wrapper {
    width: 100%;
  }

  .sentence-box {
    width: 95%;
  }

  .results-header {
    margin: auto;
    margin-top: 5%;
  }

  .sentence-box-image {
    flex-direction: column;
    padding: 0px 5px 2px 5px;
    margin-right: 0px;
    flex: 1;
  }

  .character-box {
    padding-left: 5px;
    padding-right: 5px;
    margin: 5px 0 10px 10px;
    /* top right bottom left */
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .character-box img {
    display: flex;
    width: 42px;
    height: 42px;
    margin-right: 1%;
    padding-top: 5%;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .results-container {
    padding: 20px;
    padding-bottom: 40px;
  }
}

@media (min-width: 1025px) {
  .scroll-top-button:hover {
    color: red;
    cursor: pointer;
  }

  .pagination-controls button:hover {
    color: red;
  }

  .reset-icon:hover {
    color: red;
  }

  .copy-button:hover {
    background-color: #ff0000;
  }

  .copy-icon:hover,
  .image-icon:hover,
  .info-icon:hover,
  .quote-icon:hover,
  .swap-character-icon:hover,
  .context-icon:hover {
    color: red;
    cursor: pointer;
  }

}

.results-container {
  align-items: center;
  position: relative;
  /* Position relative to the Header component */
  width: 75%;
  background-color: #1f2224;
  border-radius: 5px;
  margin: auto;
  display: flex;
  /* Use flexbox to arrange children in a row */
  flex-direction: row;
  /* Align children horizontally */
  justify-content: space-around;
  /* Distribute space evenly around the containers */
  overflow: auto;
  color: white;
  position: relative;
  padding-top: 30px;
  min-height: 50px;
  margin-bottom: 10%;
}

.scroll-top-button {
  background-color: transparent;
  font-family: 'Noto Serif JP';
  color: #c8000f;
  font-weight: 100;
  letter-spacing: 0.07em;
  border: none;
  padding: 6px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  user-select: none;
}



.content-wrapper {
  overflow-y: auto;
}

/* Results.css */
.highlight-icon {
  position: absolute;
  top: 10px;
  /* Adjust to align with the top edge of the results-container */
  right: 10px;
  /* Adjust to align with the right edge of the results-container */
  color: #c8000f;
  /* The red color you specified */
  cursor: pointer;
  /* Change the cursor to a pointer when hovering over the icon */
  opacity: 30%;
}

.highlight {
  color: red
    /* The red color you specified */
}

.highlight-icon.active {
  color: red;
  /* Change to your preferred active color */
  opacity: 100%;
}

.reset-icon {
  position: absolute;
  font-size: 80%;
  top: 12px;
  /* Adjust to align with the top edge of the results-container */
  right: 30px;
  /* Adjust to align with the right edge of the results-container */
  color: #c8000f;
  /* The red color you specified */
  cursor: pointer;
  /* Change the cursor to a pointer when hovering over the icon */
}

.chapter-section {
  margin-left: 20px;
}

.sentence-list {
  margin-left: 40px;
}

.sentences-container {
  max-height: 400px;
  padding-right: 10px;
  overflow-y: auto;
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  overflow-x: hidden;
}

.sentence-character-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.image-icon-container {
  position: relative;
}


.character-title-image {
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

.cover-image {
  width: 42px;
  height: 60px;
  margin-right: 10px;
}

.cover-image-es {
  width: 79px;
  height: 32px;
  margin-right: 10px;
}

.cover-image-ssc {
  width: 101px;
  height: 25px;
  margin-right: 10px;
}

.cover-image-es-small {
  width: 64px;
  height: 26px;
  margin-right: 10px;
}

.cover-image-apo-small {
  width: 64px;
  height: 36px;
  margin-right: 10px;
}

.apo-part-image {
  width: 96px;
  height: 39px;
}

.sentence-dimmed {
  opacity: 0.5; /* Dimmed effect */
  color: #999999; /* Gray dimmed color */
}

.sentence-dimmed:hover {
  opacity: 1; /* Increase opacity to 100% on hover */
  color: white;
  cursor: pointer;
}

.cover-image-apo-chapter-small {
  width: 90px;
  height: 23px;
  margin-right: 10px;
}

.character-trigger,
.volume-trigger,
.season-trigger {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: large;
}

.characters-trigger {
  font-size: larger;
}

.medium-trigger {
  font-size: medium;
}

.anime-trigger {
  font-size: small;
}


.character-box {
  border: 1px solid #c8000f;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
}

.popup {
  opacity: 0;
  transition: opacity 0.2s;
  background-color: #c8000f;
  color: #fff;
  font-size: medium;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -32px;
}




.popup::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #c8000f transparent transparent transparent;
}

.show {
  display: block;
  opacity: 1;
}

.hidden {
  transition: display 0.2s;
  display: none;
}


.icon-slashline {
  margin-left: 5px;
  width: 12px;
}

.icon-container {
  display: flex;
  margin: 0px;
  margin-right: 3px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.icon-container-triple {
  display: flex;
  margin: 0px;
  margin-right: 5px;
  padding: 0px;
  margin-left: 3px;
  justify-content: center;
  align-items: center;
}

.copy-icon,
.image-icon,
.quote-icon,
.info-icon {
  color: #c8000f;
  cursor: pointer;
  position: relative;
}

.swap-character-icon {
  color: #c8000f;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-top: 0.1px;
  font-size: 10px;
}

.copy-icon {
  margin-top: 4px;
}

.context-icon {
  color: #c8000f;
  cursor: pointer;
  top: 0;
  position: absolute;
  left: 0;
  padding: 5px;
  font-size: smaller;
}

.quote-icon {
  margin-top: 6px;
  margin-left: 2px;
  font-size: medium;
}

.image-icon {
  margin-top: 5px;
  margin-left: 3px;
}

.info-icon {
  margin-top: 5px;
  margin-left: 3px;
}



.Collapsible__trigger {
  display: block;
  width: 100%;
  box-sizing: border-box;
  background-color: #2c3033;
  color: #fff;
  padding: 10px 15px;
  margin: 5px 0;
  border: 1px solid #c8000f;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

br {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.Collapsible__trigger:hover {
  background-color: #3a3f42;
}

.Collapsible__trigger.is-open {
  background-color: #c8000f;
}


.sentence-box {
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #000;
  padding: 0px 5px 0px 10px;
  margin: 10px 0 10px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sentence-box-image {
  border: 1px solid #000;
  border-radius: 5px;
  width: auto;
  max-width: 90%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}



.pagination-controls button {
  background: none;
  color: #c8000f;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}


.copy-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #c8000f;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}



.info-box {
  position: absolute;
  z-index: 1;
  background-color: red;
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
  margin-left: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}



.results-header,
.filters-header,
.info-header {
  font-family: 'Noto Serif JP';
  font-size: calc(1.2em + 2.4vw);
  color: #c8000f;
  font-weight: 100;
  letter-spacing: 0.07em;
  align-items: center;
  position: flex;
  /* Position relative to the Header component */
  width: 77%;
  border-radius: 5px;
  display: flex;
  /* Use flexbox to arrange children in a row */
  position: relative;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.filters-header {
  margin: auto;
  margin-top: 1%;
}

.es-image {
  width: 88px;
  height: 45px;
  margin-right: 10px;
}

.ssc-image {
  width: 88px;
  height: 59px;
  margin-right: 10px;
}

.apo-image {
  width: 88px;
  height: 85px;
  margin-right: 10px;
}

.apo-image-small {
  width: 64px;
  height: 62px;
  margin-right: 10px;
}

.page-settings {
  height: 20px;
  display: flex;
}


.settings-spp:focus {
  border: 1px solid #c8000f;
  border-radius: 3px;
}

.pagination-controls {
  display: flex;
  gap: 10px;
  margin: auto;
}

.pagination-controls input {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: auto;
  max-width: 20px;
  text-align: center;
}


.pagination-controls button:disabled {
  color: #999999;
  /* Change this to the color you want */
  cursor: default;
}

.pagination-controls button:disabled {
  color: #999999;
  /* Change this to the color you want */
  cursor: default;
}
body {
  margin: 0;
  font-family: 'Bellota Text', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  font-weight: 700;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  overflow-x: hidden;
  grid-template-rows: auto 1fr auto;
  display: grid;
  min-height: 100vh;
}

.App {
  margin: 0;
  font-family: 'Bellota Text', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  font-weight: 700;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  overflow-x: hidden;
  grid-template-rows: auto 1fr auto;
  display: grid;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #c8000f;
  border-radius: 2px;
  margin: 2px;
  box-shadow: none;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
  background: #fff;
}
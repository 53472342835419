.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  z-index: 1;
}

.Header .title {
  display: flex;
  align-items: center;
  padding: 0 4%;
  font-family: 'Noto Serif JP';
  font-size: calc(1em + 2vw);
  color: white;
  letter-spacing: 0.07em;
  text-decoration: none;
}

.Header .title .red {
  color: #c8000f;
  font-weight: 800;
}

.Header nav {
  display: flex;
  justify-content: flex-end;
}

.Header nav ul {
  list-style-type: none;
  display: flex;
  align-items: center;
}

.Header nav li {
  padding: 0 2vw;
  flex: 1 1 auto;
}

.Header nav a {
  color: white;
  text-decoration: none;
  font-family: 'Bellota Text', cursive;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  background-size: 200% 100%;
  background-position: right;
  transition: color 0.3s ease;
}

.Header nav a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #c8000f;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease-in-out;
  z-index: -1;
}

.Header nav a:hover {
  color: #fff;
}

.Header nav a:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.logo {
  width: 200px;
  height: auto;
  transition: opacity 0.3s ease;
}

.logo:hover {
  opacity: 0.7;
}

.active-link {
  background-color: #c8000f;
}

@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .burger-open {
    color: #c8000f;
  }

  .burger-icon {
    font-size: 200%;
    position: absolute;
    color: white;
    right: 10px;
    top: 34px;
    z-index: 3;
  }

  .mobile-nav-ul {
    flex-direction: column;
    height: 100%;
    gap: 30px;
  }

  .overlay-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 30px;
    right: 0;
    width: 220px;
    height: auto;
    background-color: rgba(17, 17, 17, 1);
    padding: 2% 0 2% 0;
    border-radius: 5px 0 0 5px;
  }
}

@media (min-width: 769px) {
  .mobile-nav {
    display: none;
  }

  .burger-open:hover {
    color: white;
  }
}
@media (min-width: 1025px) {
    .context-close-icon:hover, .context-jump-button:hover {
        color: red;
        cursor: pointer;
    }

}

.context-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}


.context-content {
    display: flex;
    background-color: #1f2224;
    flex-direction: column;
    position: relative;
    width: 85%;
    height: 70%;
    padding: 20px 20px 5px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.context-close-icon,
.context-jump-button {
    color: #c8000f;
    cursor: pointer;
    position: absolute;
    padding: 5px;
    font-size: larger;
    margin-right: 2px;
}

.context-jump-button {
    background-color: transparent;
    font-family: 'Noto Serif JP';
    color: #c8000f;
    font-weight: 100;
    font-size: 15px;
    letter-spacing: 0.07em;
    border: none;
    position: relative;
}

.context-close-icon {
    top: 0;
    right: 0;
}


.context-title {
    color: #c8000f;
    align-items: center;
    font-size: x-large;
    position: flex;
    margin: 0px;
    display: flex;
    position: relative;
    padding-bottom: 5px;
}

.context-sentences {
    color: white;
    width: 100%;
    height: 90%;
    overflow: auto;
    padding: 5px;
    background-color: #2c3033;
    border-radius: 5px;
    user-select: text;
}

.current-line {
    color: #c8000f;
}

.context-navigation {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 0px;
}

.icon-slashline-context {
    width: 10px;
    margin-top: 5px;
    margin-right: 1px;
}
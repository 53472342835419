/* Results.css */
@media (min-width: 769px) {
  .dropdown-menu {
    min-width: 400px;
  }
}

@media (min-width: 1025px) {
  .dropdown-menu {
    min-width: 400px;
  }

  .reset-button:hover {
    color: red;
    cursor: pointer;
  }
  
  .named-button:hover {
    color: red;
    cursor: pointer;
    opacity: 100%;
  }
}

/* Hide desktop navigation on screens smaller than or equal to 768px */
@media (max-width: 768px) {
  .dropdown-menu {
    min-width: none;
    width: 90%;
  }
}

.characters-container-image {
  width: 45px;
  height: 45px;
  margin-right: 5px;
}
.characters-container-image-small {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.characters-container {
  position: relative;
  padding-bottom: 40px;
}

/* Adjust the input[type="checkbox"] to remove margin-left */
input[type="checkbox"] {
  background-color: #000000;
  accent-color: #c8000f;
}

.character-list {
  max-height: 500px; /* Adjust this value as needed */
  overflow-y: auto; /* This will add a vertical scrollbar */
  overflow-x: hidden;
  padding-left: 2px;
  padding-right: 4%;
  padding-top: 2%;
  padding-bottom: 3%;
}

.reset-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #c8000f;
  cursor: pointer;
    /* Add additional styles for the button as needed */
}

.named-button {
  position: absolute;
  bottom: 10px;
  right: 30px;
  color: #c8000f;
  font-size: small;
  opacity: 40%;
  cursor: pointer;
  /* Add additional styles for the button as needed */
}

.named-button.active {
  color: red;
  opacity: 100%;
}
/* Footer.css */
@media (max-width: 768px) {
    .footer {
        display: none;
    }
}

@media (min-width: 768px) {
    .footer {
        display: block;
    }
}

.footer {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    text-align: right;
    padding: 10px;
}
/* Keywords.css */

@media (min-width: 1025px) {
  .keywords-search-container {
    width: 90%;
  }
  .case-sensitive-icon {
    top: 8px;
    font-size: 2.5vh;
    right: 93px; /* Adjust this value as needed */
  }

  .delete-all-icon {
    font-size: 2vh;
  }

  .whole-word-icon {
    top: 8px;
    font-size: 2.5vh;
  }

  .regex-icon {
    font-size: 2.25vh;
  }
  
  .keywords-search {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #c8000f;
    margin-right: 20px;
    margin-top: 30px;
    cursor: pointer;
  }
  
  .keywords-container {
    width: 90%;
  }

  .keywords-input {
    max-width: 50%;
  }

  .keywords-input-container {
    padding-top: 30px;
    padding: 10px;
  }
  
  .reset-button:hover {
    color: red;
    cursor: pointer;
  }

  .remove-keyword-icon:hover {
    color: red;
  }

  .delete-all:hover, .case-sensitive-icon:hover, .whole-word-icon:hover, .regex-icon:hover {
    opacity: 100%;
    color: red;
    cursor: pointer;
  }

  .case-sensitive-icon.active:hover, .whole-word-icon.active:hover, .regex-icon.active:hover {
    color: #c8000f;
    cursor: pointer;
  }
}

/* Hide desktop navigation on screens smaller than or equal to 768px */
@media (max-width: 1024px) {
  .case-sensitive-icon {
    top: 10px;
    font-size: calc(0.7em + 1.3vw);
    right: 88px; /* Adjust this value as needed */
  }

  .delete-all-icon {
    font-size: calc(0.7em + 1.3vw);
  }

  .whole-word-icon {
    top: 11px;
    font-size: calc(0.5em + 1.3vw);
  }

  .regex-icon {
    font-size: calc(0.7em + 1.4vw);
  }
  .keywords-search-container {
    width: 100%;
  }

  .keywords-search {
    display: none;
  }

  .keywords-container {
    width: 100%;
  }

  .keywords-input {
    max-width: 100%;
  }

  .keywords-input-container {
    padding: 10px 10px 10px 10px;
    padding-top: 30px;
  }

  

}

.keywords-container {
    display: flex;
    flex-direction: column;
    background-color: #2c2f33;
    padding: 15px 0 15px 15px;
    margin-right: 20px;
    border-radius: 5px;
    padding-top: 0;
    position: relative; /* Add this to position the delete button */
    justify-content: space-between;
  }

  .keywords-search-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #2c2f33;
    border-radius: 5px;
    margin-top: 50px;
  }

  
  .keywords-title {
    margin-top: 0;
    font-family: 'Noto Serif JP';
    font-size: calc(0.9em + 1.8vw);
    color: #c8000f;
    font-weight: 100;
    letter-spacing: 0.07em;
    margin-bottom: 10px;
    border-bottom: 1px solid white;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  
  .keywords-input-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
  }
  
  .keyword-bubble {
    display: flex;
    align-items: center;
    background-color: #1f2224;
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 5px;
  }

  .keywords-input-wrapper {
    position: relative; /* Add this to create a new positioning context */
    border: 1px solid #c8000f;
    border-radius: 5px;
  }

  .remove-keyword-icon {
    color: #c8000f;
  }
  
  
  
  .remove-keyword {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 10px;
  }

  .input-instructions {
    font-style: italic;
    color: #c8000f; /* Adjust color as needed */
    font-size: x-small;
    margin: 0;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  
  .keywords-input {
    background-color: #2c2f33;
    color: white;
    border: none;
  }

  .regex-input[type="text"]{
    background-color: #2c2f33;
    color: white;
  }

  .regex-input[type="text"].invalid-regex {
    background-color: #2c2f33;
    color: #c8000f;
  }

  .delete-all {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    color: #c8000f;
    position: absolute;
  }

 .case-sensitive-icon {
    position: absolute;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    color: #c8000f;
    opacity: 40%;
    cursor: pointer;
    position: absolute;
  }

  .whole-word-icon {
    position: absolute;
    right: 64px; /* Adjust this value as needed */
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    color: #c8000f;
    opacity: 40%;
    cursor: pointer;
  }

  .regex-icon {
    position: absolute;
    top: 9px; /* Adjust this value as needed */
    right: 36px; /* Adjust this value as needed */
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    color: #c8000f;
    opacity: 40%;
    cursor: pointer;
  }

  .case-sensitive-icon.active, .whole-word-icon.active, .regex-icon.active {
    opacity: 100%;
    cursor: pointer;
    color: red;
    cursor: pointer;
  }

  .icons-container {
    display: flex;
    align-items: center;
  }

  .reset-button {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: #c8000f;
    font-size: small;
    /* Add additional styles for the button as needed */
  }




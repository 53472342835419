@media (min-width: 1025px) {
  .selected-container {
    width: min-content;
  }
}



.selected-subtitle {
  margin-top: -8px;
  font-family: 'Noto Serif JP';
  font-size: calc(0.3em + 1vw);
  color: #c8000f;
  font-weight: 100;
  letter-spacing: 0.07em;
  margin-bottom: 0px;
}

.selected-subtitle-char {
  margin-top: -1px;
  font-family: 'Noto Serif JP';
  font-size: calc(0.3em + 1vw);
  color: #c8000f;
  font-weight: 100;
  letter-spacing: 0.07em;
  margin-bottom: 0px;
}

.selected-title {
  font-family: 'Noto Serif JP';
  font-size: calc(0.9em + 1.8vw);
  color: #c8000f;
  font-weight: 100;
  letter-spacing: 0.07em;
  margin-bottom: 10px;
  border-bottom: 1px solid white;
}

.selected-container {
  flex-direction: column;
  background-color: #2c2f33;
  position: relative;
  height: auto;
}

.selected-input-container {
  border: 1px solid #c8000f;
  border-radius: 4px;
  padding: 10px;
  min-height: 6.5vh;
  max-height: 8vh;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 5px;
}


.selected-bubble {
  text-wrap: nowrap;
  display: flex;
  text-align: center;
  align-items: center;
  background-color: #1f2224;
  font-size: small;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  max-height: 20px;

}

@keyframes flash-selected {
  0% {
    background-color: #2c2f33;
  }

  50% {
    background-color: #363a3e;
  }

  /* Change this to the color you want */
  100% {
    background-color: #2c2f33;
  }
}

.flash-selected {
  animation: flash-selected 1s infinite;
}

@keyframes flash {
  0% {
    color: #c8000f;
  }

  50% {
    color: #ff0112
  }

  /* Change this to the color you want */
  100% {
    color: #c8000f;
  }
}

.flash {
  animation: flash 1s infinite;
}